
					@import './src/sass/variables';
					@import './src/sass/mixins';
				

















































h2, .h2 {
	margin-bottom: 0.5em;
}

.inline-label {
	margin-bottom: $default_padding / 2;
}

.block-inner {
	& > ul {
		display: inline-block;
		list-style-type: none;
		padding-left: 0;

		a {
			text-decoration: none;
			color: $color__text;
		}
	}
}

.inline-label {
	transition: box-shadow .2s ease;

	&:hover {
		box-shadow: $box_shadow;
	}
}
